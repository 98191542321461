import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Layout from '../../components/mini/layout';
import SEO from '../../components/mini/seo';
import settings from '../../../settings';

const topbanner = `${settings.IMAGES_BASE_URL}/images/404-images/mini-404-banner.jpg`;

const Mini404 = () => (
  <Layout>
    <SEO title="THE MINI DRIVING EXPERIENCE | 404" />
 
    <div className="error-page">
      <LazyLoadImage effect="blur" src={topbanner} alt="BMW 404 Page" />
      <div className="container">
        <h1>404 error page</h1>
        <p>
          Looks like you’ve come to a kink in the road. No problem - just follow a link below and get back on the right track.
        </p>
        <div className="buttons">
          <Link to="/mini"><button className="btn btn-bordered">MINI Driving Experience home</button></Link>
          <Link to="/mini/schools"><button className="btn btn-bordered">MINI Driving Experience classes</button></Link>
        </div>
      </div>
    </div>
  </Layout>
);

export default Mini404;